:root {
  --green: #86ae34;
  --text-color: #8b8b8b;
  --font-handwriting: "Pacifico", cursive;
  --font-tajwal: "Tajawal", sans-serif;
}

@import "style/reset.scss";
@import "style/header/header.scss";
@import "components/footer/footer.scss";

section.hero {
  position: relative;
  background: url("./imges/banner.jpg") center bottom;
  height: calc(70vh - 51px);
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    position: relative;
    z-index: 2;
    color: #b9b9b9;
    text-align: center;
    font-size: 45px;
    height: 66px;
    overflow: hidden;
    .dynamic-container {
      display: inline-flex;
      flex-direction: column;
    }
  }

  .dynamic-word {
    color: var(--green);
  }
}

section.wellcom {
  display:none;
  font-family: var(--font-handwriting);
  text-align: center;
  padding-bottom: 50px;
  position: relative;
  // overflow: hidden;

  .title {
    color: var(--green);
    font-size: 30px;
  }

  .cycle-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .cycle {
      padding: 5px 10px;
      max-width:300px;
      h3{
    color: #392a25;

      }
      p:first-child {
        color: var(--green);
        font-size: 100px;
        margin: 0;
      }
      p:last-child {
        font-family: var(--font-tajwal);
        color: var(--text-color);
      }
    }
  }
  .float-left,.float-right{
    position: absolute;
    width: 150px;
  }
  .float-left{
    left: -50px;
    top: 50px;
  }
  .float-right{
    right: -80px;
    bottom: 286px;
  }
  @media screen and(min-width:600px) {
    display:block;
    .float-right{
      bottom: 200px;
    }
  }
  @media screen and(min-width:780px) {
    .title {
      font-size: 48px;
    }
  }
  @media screen and(min-width:900px) {
    .float-right{
      bottom: -100px;
    }
  }
}
section.vedio-slider{
  max-width: 1124px;
    margin: auto;
  .swiper {
    width: 75%;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    position: relative;
    .product{
      position:relative;
      background-size:cover;border:2px solid green;
      width: 300px;
      height: 200px;
    }
  
    .vid1{
      background-image:url("./imges/vslid1.jpg") ;
    }
    .vid2{
      background-image:url("./imges/vslid2.jpg") ;
    }
    .vid3{
      background-image:url("./imges/vslid3.jpg") ;
    }

  }





  
  .video-play-button {
  
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    box-sizing: content-box;
    display: block;
    width: 32px;
    height: 44px;
    /* background: #fa183d; */
    border-radius: 50%;
    padding: 18px 20px 18px 28px;
  }
  
  .video-play-button:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 80px;
    height: 80px;
    background: #ba1f24;
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
  }
  
  .video-play-button:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 80px;
    height: 80px;
    background: #fa183d;
    border-radius: 50%;
    transition: all 200ms;
  }
  
  .video-play-button:hover:after {
    background-color: darken(#fa183d, 10%);
  }
  
  .video-play-button img {
    position: relative;
    z-index: 3;
    max-width: 100%;
    width: auto;
    height: auto;
  }
  
  .video-play-button span {
    display: block;
    position: relative;
    z-index: 3;
    width: 0;
    height: 0;
    border-left: 32px solid #fff;
    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;
  }
  
  @keyframes pulse-border {
    0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
    }
    100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
      opacity: 0;
    }
  }
  
}

.video-overlay {
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,1);
  opacity: 0;
  transition: all ease 500ms;
}

.video-overlay.open {
  position: fixed;
  z-index: 1000;
  opacity: 1;
}

.video-overlay-close {
  position: absolute;
  z-index: 1000;
  top: 15px;
  right: 20px;
  font-size: 36px;
  line-height: 1;
  font-weight: 400;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  transition: all 200ms;
}

.video-overlay-close:hover {
  color: #fa183d;
}

.video-overlay iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  /* width: 90%; */
  /* height: auto; */
  box-shadow: 0 0 15px rgba(0,0,0,0);
}

section.banner {
  margin-top: 50px;
  padding-top: 20px;
  background-image: url("./imges/banner.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  min-height: 65vh;
  h3 {
    font-size: 70px;
    font-weight: 700;
    margin: 0;
  }
  p {
    font-family: var(--font-handwriting);
    font-size: 30px;
  }

  button {
    border: none;
    background-color: var(--green);
    padding: 10px 30px;
    color: black;
    transition: all 1s;

    &:hover {
      color: white;
      background-color: rgb(68, 6, 60);
    }
  }
}
section.prod-feature {
  padding-top: 100px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  img {
    width: 250px;
    height: 250px;
  }
}


section.why-us {
  position: relative;
  // margin: 100px 0;

  .hero {
    background: url("./imges/banner-green.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    color: rgb(255, 255, 255);
    // height: 80vh;
    height: 750px;
    width: 100vw;
    position: relative;
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(
        to bottom,
        rgba(45, 45, 45, 0) 0%,
        rgba(45, 45, 45, 0.15) 12.5%,
        rgba(45, 45, 45, 0.3) 25%,
        rgba(45, 45, 45, 0.3) 75%,
        rgba(45, 45, 45, 0.15) 87.5%,
        rgba(45, 45, 45, 0) 100%
      );
      width: 100%;
      height: 100%;
    }
  }
  .counters-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: white;
    text-align: center;
    h2 {
      text-transform: uppercase;
      font-size: 40px;
      margin-top: 5vh;
    }
    .more {
      font-size: 0.8rem;
      width: 50%;
      margin: 0.5em auto 0.5em auto;
    }
    .counter-container {
      display: flex;
      flex-direction: column;
      width: 75%;
      margin: 0 auto;
      justify-content: space-around;
      .counter-item {
        text-align: center;
        margin: 0 auto;
      }
      .counter {
        // font-size: 3rem;
        font-size: 2rem;
        font-weight: 700;
        margin: 0;
      }
      p {
        // margin-top: 8px;
        margin-top: 5px;
        font-size: 30px;
        width: 100%;
        font-weight: 700;
      }
    }
  }
  @media screen and(min-width:780px) {
    .hero {
      height: 70vh;
    }
    .counters-wrapper {
      h2 {
        margin-top: 8vh;
      }
      .counter-container {
        display: flex;
        flex-direction: row;
        .counter {
          font-size: 3rem;
        }
        p {
          margin-top: 8px;
        }
      }
    }
  }
}
section.week-deal {
  background: url("./imges/deals-week.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  color: rgb(255, 255, 255);
  text-align: center;
  .container {
    padding: 90px 20px;
    display: flex;

    justify-content: center;
    flex-direction: column;
  }
  .left{
    h3 {
      font-size: 48px;
      margin: 0;
      span {
        color: #ff6924;
      }
    }
    .unit,.price,.stock,.product{
      display:inline-block;
      padding: 2px 8px;
      border-radius: 10px;
      
    }
    .unit,.price,.product{
      background:  #47cf73;
    }
    .stock{
      background:  #ff6924;
    }
  }
  .right {
    display: flex;
    align-items: center;
    .product{
      text-align: center;
      width: 100%;
    }
    img {
      min-width: 280px;
      max-width: 70vw;
      margin-top: 10px;
      border: 7px solid #ff6924;
      // border-radius: 200px;
    }
  }

  @media screen and(min-width:800px){
    .container {
        justify-content: space-around;
        flex-direction: row;
    }
  }
  .right {

    img {
      max-width: 35vw;
    }
  }
}
section.category{
  padding: 60px 0;
  text-align:center;
  h3{
    font-size: 40px;
    font-family: var(--font-handwriting);
    padding-bottom: 30px;
  }
  .wrapper{
  display:flex;
  justify-content:center;
  flex-wrap: wrap;
  div{
    margin: 8px;
  }
    .items{
      border:1px solid rgba(128, 128, 128, 0.2);
      position:relative;
      padding: 50px 10px 5px 10px;
      margin: 55px 5px 0;
      min-width: 200px;
      img{
        width: 100px;
        height: 100px;
        position:absolute;
        top:-50px;
        left:50%;
        margin-left:-50px;
        border:2px solid rgba(128, 128, 128, 0.2);
        border-radius:100px;
      }
      p{
        font-size:30px;
        text-transform:uppercase;
        margin: 0;
      }
    }
 }
}





// start shipping page //
.shipping-hero {
  background: url("./imges/delivery.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  color: rgb(255, 255, 255);
  height: 55vh;
  width: 100vw;
  position: relative;
  &::after {
    content: "";
    display: block;
    background: linear-gradient(
      to bottom,
      rgba(45, 45, 45, 0.4) 0%,
      rgba(45, 45, 45, 0.9) 100%
    );
    width: 100%;
    height: 100%;
  }
}
.shipping-content {
  padding: 50px 20px;
}

.schedule{
  h1{
    text-align: center;
    margin: 40px 0;
  }
  .cont {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    
    .day {
      width: 100vw;
      padding: 8px;
      background: #6028d0;
      text-align: center;
      margin: auto;
      color: white;
      font-size: 25px;
      margin-top: 10px;
    }
    .regions {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      span {
        background: #35ab5b;
        padding: 8px;
        border-radius: 5px;
        margin: 5px 2px;
        font-size: 20px;
        color: rgb(255, 254, 254);
      }
    }
  }
}
// end shipping page //

// start Product Page //
.products-hero {
  background: url("./imges/banner.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  color: rgb(255, 255, 255);
  height: 55vh;
  width: 100vw;
  position: relative;
  &::after {
    content: "";
    display: block;
    background: linear-gradient(
      to bottom,
      rgba(45, 45, 45, 0.2) 0%,
      rgba(45, 45, 45, 0.9) 100%
    );
    width: 100%;
    height: 100%;
  }
}
.product-filter {
  text-align: center;
  padding: 50px 0 20px 0;
  ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    & li:first-child{
      background: none;
      padding-top: 8px;
    }
  }
  li {
    margin: 5px 2px;
    padding: 5px 10px 0px 10px;
    background: #501eb317;
    border-radius: 10px;
  }
  .active{
    background: #501eb3;
    
    a{
      color: white;
    }
  }

}
.product-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .product {
     &:hover .img-wrapper .back{
    top: calc(0% + 1px);
  }
    border: 2px solid rgba(128, 128, 128, 0.2);
    padding-bottom: 10px;
    // margin: 2px;
    max-width: 320px;
    border-radius: 5px;
    .img-wrapper {
      overflow: hidden;
      position: relative;
      .back {
        position: absolute;
        top: 100%;
        bottom:0;
        left: 1px;
        right: 0;
        padding: 2px;
        background: #501eb3;
        color: rgb(210, 210, 210);
        // width: 100%;
        border-radius: 5px;
        z-index: 2;
        transition: top 0.5s;
          
          h6{
            font-size:24px;
            margin: 5px;
            text-align:center;
       
            }
          li{
            padding-left: 25px;
            position: relative;
            font-size: 22px;
            &:before{
             position: absolute;
              content: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMWVtIiBoZWlnaHQ9IjFlbSIgZmlsbD0id2hpdGUiIHN0cm9rZT0iY3VycmVudENvbG9yIiBzdHJva2Utd2lkdGg9IjAiIHZpZXdCb3g9IjAgMCAxMDI0IDEwMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik00MzMuMSA2NTcuN2EzMS44IDMxLjggMCAwIDAgNTEuNyAwbDIxMC42LTI5MmMzLjgtNS4zIDAtMTIuNy02LjUtMTIuN0g2NDJjLTEwLjIgMC0xOS45IDQuOS0yNS45IDEzLjNMNDU5IDU4NC4zbC03MS4yLTk4LjhjLTYtOC4zLTE1LjYtMTMuMy0yNS45LTEzLjNIMzE1Yy02LjUgMC0xMC4zIDcuNC02LjUgMTIuN2wxMjQuNiAxNzIuOHoiLz4KPHBhdGggZD0iTTg4MCAxMTJIMTQ0Yy0xNy43IDAtMzIgMTQuMy0zMiAzMnY3MzZjMCAxNy43IDE0LjMgMzIgMzIgMzJoNzM2YzE3LjcgMCAzMi0xNC4zIDMyLTMyVjE0NGMwLTE3LjctMTQuMy0zMi0zMi0zMnptLTQwIDcyOEgxODRWMTg0aDY1NnY2NTZ6Ii8+Cjwvc3ZnPgo=);
              display: block;
              width: 22px;
              height: 10px;
              margin: 10px 5px 0 10px;
              left: -8px;
              top: -9px;
            }
          }
      }
    }
    .show-in-season-badge{
      position: absolute;
      background: #89ae38;
      padding: 2px 10px;
      border-radius: 0 15px 15px 0;
      color: white;
      font-size: 15px;
      z-index: 2;
    }
    .hide-in-season-badge{
      display: none;
    }
    img {
      width: 100%;
    }
  }
  .product-detials {
    padding: 0 10px;

    .price-cart-container {
      display: flex;
      justify-content: space-between;
      padding-top: 15px;

      .price {
        color: #89ae38;
      }
      button {
        padding: 5px 20px;
        background-color: #47cf73;
        border: none;
        border-radius: 2px;
        color: white;
      }
    }
    hr {
      height: 1px;
      background-color: rgba(204, 204, 204, 0.5);
      border: none;
    }
    p {
      margin: 0;
    }
    .name {
      color: #7a7a7a;
      font-weight: 500;
      text-align: center;
    }
  }
}

// end Product Page //
// .f {
//   background: url("./imges/banner.jpg") no-repeat;
//   background-size: cover;
//   background-position: center;
//   color: rgb(255, 255, 255);
//   height: 55vh;
//   width: 100vw;
//   position: relative;
//   &::after {
//     content: "";
//     display: block;
//     background: linear-gradient(
//       to bottom,
//       rgba(45, 45, 45, 0.2) 0%,
//       rgba(45, 45, 45, 0.9) 100%
//     );
//     width: 100%;
//     height: 100%;
//   }
// }


// S-contact page //
.contact-hero{

  background: url("https://preetheme.com/liton/vegita/assets/img/banner/page.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  .page-info{
    padding: 20px;
  .title{
    font-size: 40px;
    font-weight: 900;
    color: white;
  }  
  }
  p{
    color: #47cf73;
    margin: 0;
  }
  @media screen and(min-width:500px){
    .page-info{
      padding: 40px;
    .title{
      font-size: 70px;
    } 
  }
}
}
.contact-detials {
  padding: 50px 0;
  width: 90vw;
  margin:auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div{
    background-color: #F2F2F2;
    padding: 10px;
    width: 300px;
    border-radius: 20px;
    margin: 5px ;
    min-height: 190px;
    svg{
      fill: #47cf73;
      font-size: 40px;
    }
    p,h6{
      margin: 0;
    }
    h6{
      font-size: 25px;
    }
  }
  @media screen and(min-width:500px){
    flex-direction: row;
    flex-wrap: wrap;
  }
}
// E-contact page //



.registeration{
  position: absolute;
  width: 320px;
  right: 0;
  top: 0;
  background: #501eb3;
  color: wheat;
  padding: 10px;
  z-index: 1000;
  .container{
    display: flex;
    flex-direction: column;
  }
}



.cart-product  {
  display:flex;
  img{
    width: 100px;
  }

  .prod-detials {

    div{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .qty{
        border: none;
        height: 30px;
      }
      p{
        padding: 0;
        margin: 0;
      }
    }
  }
}