html,body{
  overflow-x:hidden ;
}
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
body {
  padding: 0;
  margin: 0;
  background: rgb(255, 255, 255);
  color: rgb(44, 44, 44);
  font-family: "Tajawal", sans-serif;
}
a {
  text-decoration: none;
}
ul,
li {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

p{
  font-size: 20px;
}
