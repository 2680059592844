.social {
  display: flex;
  div {
    margin: 0 3px;
    width: 20px;
    height: 20px;
  }
  .twitter {
    fill: var(--base-clr);
    &:hover {
      fill: rgb(0, 183, 255);
    }
  }
  .youtube {
    fill: var(--base-clr);
    &:hover {
      fill: rgb(248, 41, 41);
    }
  }
  .facebook {
    fill: var(--base-clr);
    &:hover {
      fill: rgb(0, 98, 255);
    }
  }
  .whatsapp {
    fill: var(--base-clr);
    &:hover {
      fill: rgb(2, 219, 85);
    }
  }
}
