footer {
  padding-top: 50px;
  // margin-top: 50px;
  background-color: rgb(193, 231, 217);
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  .row {
    p:not(.title) {
      color: var(--text-color);
      // color: white;
    }
    max-width: 250px;
    font-size: 20px;
    .title {
      font-family: var(--font-handwriting);
      font-weight: 600;
    }
    a{
      color:gray;
    }
  }
  .slogan {
    .txt {
      padding: 0;
      margin-top: -5px;
    }
    p:last-child {
      font-size: 14px;
    }
    text-align: center;
  }
}
.copy-rights {
  text-align: center;
  background-color: rgb(225, 231, 228);
  padding: 5px;
  font-size: 16px;
}
