header {
  --base-clr: gray;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo {
    img {
      height: 50px;
      width: 208px;
    }
  }
  @media screen and(max-width:850px) {
    padding: 0 10px;
  }
  .social {
    @media screen and(max-width:850px) {
      display: none;
    }
  }
}

@import "nav.scss";
@import "social.scss";

.nav-menu-button {
  @media screen and(min-width:700px) {
    display: none;
  }
  div {
    width: 35px;
    height: 4px;
    background: black;
    margin: 5px;
    border-radius: 5px;
  }
}

.cart{
  position: fixed;
  width: 40px;
  height: 40px;
  padding: 5px;
  border-radius: 10px;
  right: 15px;
  top:50vh;
  z-index: 100;
  background-color: white;

  svg{
    width: 20px;
    margin: 10px 0 0 4px;
  }
  p{
    color:red;
    position: absolute;
    top: -20px;
    left: 16px;
    font-size: 17px;
    font-weight: bold;


  }
}
.fav{
  position: fixed;
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 10px;
  right: 15px;
  top:calc(50vh + 45px);
  z-index: 100;
  background-color: white;

  svg{
    width: 20px;
    margin-top: 3px;
  }
}